
//FONT
$body-color: #000000;
$font-family-sans-serif: 'Qanelas', sans-serif !default;

/* custom bootstrap*/
@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";


$grid-breakpoints: (
  xs: 0,
  sm: 577px,
  md: 768px,
  lg: 1024px,
  xl: 9000px
);

$container-max-widths: (
  sm: 768px,
  md: 1024px,
  lg: 1280px,
  xl: 1920px
);

$grid-gutter-width : 80px;



@import "../../node_modules/bootstrap/scss/bootstrap";

