@import 'global';

@font-face {
	font-family: 'Gotham Book';
	src: url('../font/gotham/GothamBook.woff2') format('woff2'),
	url('../font/gotham/GothamBook.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Gotham Book';
	src: url('../font/gotham/GothamBook-Italic.woff2') format('woff2'),
	url('../font/gotham/GothamBook-Italic.woff') format('woff');
	font-weight: normal;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Gotham';
	src: url('../font/gotham/Gotham-Medium.woff2') format('woff2'),
	url('../font/gotham/Gotham-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Gotham';
	src: url('../font/gotham/Gotham-Black.woff2') format('woff2'),
	url('../font/gotham/Gotham-Black.woff') format('woff');
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Gotham';
	src: url('../font/gotham/GothamBold.woff2') format('woff2'),
	url('../font/gotham/GothamBold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'UderzoTiTR';
	src: url('../font/uderit/UderzoTiTR.woff2') format('woff2'),
	url('../font/uderit/UderzoTiTR.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'UderzoTeXT';
	src: url('../font/udertx/UderzoTeXT.woff2') format('woff2'),
	url('../font/udertx/UderzoTeXT.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}




.ff-gotham{ font-family:'Gotham'; }
.ff-gotham-book{ font-family:'Gotham Book'; }
.ff-uder-it{ font-family:'UderzoTiTR'; }
.ff-uder-xt{ font-family:'UderzoTeXT'; }

.fw-thin{ font-weight: 300; }
.fw-regular{ font-weight: 500; }
.fw-semibold{ font-weight: 600; }
.fw-bold{ font-weight: bold; }
.fw-extrabold{ font-weight: 800; }
.fw-black{ font-weight: 900; }

.fs-uppercase{ text-transform: uppercase; }
.fs-underline{ text-decoration: underline; }
.fs-italic{ font-style: italic; }

.fc-white{	color : $white-color; }

/*génération des taille de polices mobile*/

$mobile_font_size : 35,24,20;

@each $i in $mobile_font_size {

	.fs-#{$i}{
		font-size : get-mobile-vw(1px*$i);
	}
}

/*génération des taille de polices small desktop */
@media screen and (min-width : map-get($grid-breakpoints, lg)) and (max-width : ($desktop_base_size - 1) * 1px ){

	$desktop_font_size : 20;

	@each $i in $desktop_font_size {

		.fs-d-#{$i}{
			font-size : get-desktop-vw(1px*$i);
		}
	}

}

/*génération des taille de polices desktop */
@media screen and (min-width : $desktop_base_size * 1px){

	$desktop_font_size : 20;

	@each $i in $desktop_font_size {

		.fs-d-#{$i}{
			font-size : 1px*$i;
		}
	}

}